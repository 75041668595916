import html2canvas from 'html2canvas';

export const formatLargeNumber = (num) => {
  const roundedNum = parseFloat(num.toFixed(2));
  if (roundedNum >= 1000000000) {
    return (roundedNum / 1000000000).toFixed(2).replace(/\.?0+$/, '') + 'B';
  }
  if (roundedNum >= 1000000) {
    return (roundedNum / 1000000).toFixed(2).replace(/\.?0+$/, '') + 'M';
  }
  if (roundedNum >= 1000) {
    return (roundedNum / 1000).toFixed(2).replace(/\.?0+$/, '') + 'K';
  }
  return roundedNum.toString();
};

export const formatLabel = (label) => {
  return label
    ?.split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const createImageWrapper = (chartRef, cardTitle, statsData, selectedYAxis) => {
  if (!chartRef?.current) {
    throw new Error('Chart reference not found');
  }

  // Create wrapper div
  const wrapper = document.createElement('div');
  wrapper.style.backgroundColor = '#ffffff';
  wrapper.style.padding = '20px';
  wrapper.style.width = chartRef.current.offsetWidth + 'px';
  wrapper.style.position = 'relative';
  wrapper.style.fontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif';

  // Add title
  if (cardTitle) {
    const titleDiv = document.createElement('div');
    titleDiv.style.fontSize = '18px';
    titleDiv.style.fontWeight = 'bold';
    titleDiv.style.marginBottom = '12px';
    titleDiv.style.color = '#374151';
    titleDiv.textContent = cardTitle;
    wrapper.appendChild(titleDiv);
  }

  // Add stats if available
  if (statsData) {
    const statsDiv = document.createElement('div');
    statsDiv.style.backgroundColor = '#f3f4f6';
    statsDiv.style.padding = '8px 16px';
    statsDiv.style.borderRadius = '6px';
    statsDiv.style.marginBottom = '16px';
    statsDiv.style.fontSize = '14px';
    
    const yAxisSpan = document.createElement('div');
    yAxisSpan.style.color = '#6b7280';
    yAxisSpan.style.fontSize = '12px';
    yAxisSpan.style.marginBottom = '4px';
    yAxisSpan.textContent = formatLabel(selectedYAxis);
    statsDiv.appendChild(yAxisSpan);

    const statsContent = document.createElement('div');
    statsContent.style.color = '#4b5563';
    statsContent.innerHTML = `
      <span style="margin-right: 16px">
        <strong>Sum:</strong> ${formatLargeNumber(parseFloat(statsData.sum))}
      </span>
      <span>
        <strong>Avg:</strong> ${formatLargeNumber(parseFloat(statsData.avg))}
      </span>
    `;
    statsDiv.appendChild(statsContent);
    
    wrapper.appendChild(statsDiv);
  }

  // Clone the chart
  const chartClone = chartRef.current.cloneNode(true);
  wrapper.appendChild(chartClone);

  // Add watermark div
  const watermark = document.createElement('div');
  watermark.style.cssText = `
    position: absolute;
    bottom: 17px; /* Shifted up 5px from original 12px */
    right: 12px;
    padding: 6px 10px;
    border-radius: 4px;
    z-index: 1000;
    text-align: right;
    font-style: italic;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, system-ui, sans-serif;
    font-size: 12px;
    line-height: 1;
    background-color: rgba(255, 255, 255, 0.9);
  `;

  const text = document.createElement('span');
  text.style.cssText = `
    color: #FFADFF; /* secondary color */
  `;
  text.textContent = 'generated with ';

  const brandName = document.createElement('span');
  brandName.style.cssText = `
    color: #170DF2; /* primary color */
    font-weight: bold;
  `;
  brandName.textContent = 'tango AI';

  watermark.appendChild(text);
  watermark.appendChild(brandName);
  wrapper.appendChild(watermark);

  return wrapper;
};

export const captureImage = async (wrapper) => {
  try {
    document.body.appendChild(wrapper);
    await new Promise(resolve => setTimeout(resolve, 100));

    console.log('Starting capture with wrapper dimensions:', wrapper.offsetWidth, 'x', wrapper.offsetHeight);

    const canvas = await html2canvas(wrapper, {
      scale: 2,
      backgroundColor: '#ffffff',
      logging: true,
      useCORS: true,
      onclone: (clonedDoc) => {
        const svgElements = clonedDoc.getElementsByTagName('svg');
        Array.from(svgElements).forEach(svg => {
          svg.style.width = '100%';
          svg.style.height = '100%';
        });
      }
    });

    document.body.removeChild(wrapper);

    console.log('Capture complete - canvas dimensions:', canvas.width, 'x', canvas.height);
    return canvas;
  } catch (error) {
    console.error('Error in captureImage:', error);
    throw error;
  }
};
import React, { useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useAsk } from '../contexts/AskContext';
import QuerySection from '../components/Dashboards/QuerySection';
import DashboardManager from '../components/Dashboards/DashboardManager';
import { useConfig } from '../contexts/ConfigContext';

const Ask = ({ mainContentRef }) => {
  const { currentUser, isAdmin } = useAuth();
  const {
    queryResult,
    isLoading,
    error,
    setError,
    isQueryJustAdded,
    setIsQueryJustAdded,
    handleQueryResult,
    handleQueryStart,
    handleClearResult
  } = useAsk();

  const { config, isLoading: isConfigLoading } = useConfig();

  
  const submitQuery = useCallback(
    async (query, isFollowUp = false, highlightedText = '', originalSqlQuery = '') => {
      handleQueryStart();
      try {
        const userId = currentUser?.email || 'anonymous';
        const userQuery = query;

        const response = await fetch(
          'https://us-central1-' + config.core.projectId + '.cloudfunctions.net/processDynamicQueryWithCORS',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              data: {
                userQuery: userQuery,
                userId: userId,
                isFollowUp: isFollowUp,
                isAdmin: isAdmin,
                highlightedText: highlightedText,
                originalSqlQuery: originalSqlQuery,
                originalDocumentId: queryResult ? queryResult.documentId : null,
                originalSavedQueryId: queryResult ? queryResult.savedQueryId : null,
              },
              context: { auth: true },
            }),
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error('Failed to process query.');
        }

        const newResult = {
          result: data.result || [],
          sqlQuery: data.sqlQuery || '',
          schema: data.schema || '',
          userQuery: data.userQuery || query,
          truncationMessage: data.truncationMessage || '',
          truncated: data.truncated || false,
          explanation: data.explanation || '',
          isFollowUp: data.isFollowUp || isFollowUp,
          originalQuery: data.originalQuery || '',
          documentId: data.documentId || (queryResult ? queryResult.documentId : null),
          savedQueryId: data.savedQueryId || (queryResult ? queryResult.savedQueryId : null),
          config: {
            usedModel: data.usedModel || 'Unknown',
            fallbackUsed: data.fallbackUsed || false,
          },
          retryCount: data.retryCount || 0,
          retryResult: data.retryResult || 'N/A',
          bigQueryError: data.bigQueryError || null,
          status: data.status || 'completed',
          endTime: data.endTime || new Date().toISOString(),
        };

        handleQueryResult(newResult);
      } catch (error) {
        console.error('Error submitting query:', error);
        setError({
          message: 'An unexpected error occurred. Please try again.',
          status: 'error',
        });
        handleQueryResult(null);
      }
    },
    [currentUser, handleQueryStart, handleQueryResult, queryResult, isAdmin, setError]
  );

  const handleFollowUpQuery = useCallback(
    (followUpQuery, highlightedText, originalSqlQuery) => {
      submitQuery(followUpQuery, true, highlightedText, originalSqlQuery);
    },
    [submitQuery]
  );

  const handleSaveQuery = useCallback(async (name, isPrivate = true) => {
    if (!queryResult) return;

    try {
      const response = await fetch(
        'https://us-central1-' + config.core.projectId + '.cloudfunctions.net/saveQuery',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            data: {
              name,
              userQuery: queryResult.userQuery,
              sqlQuery: queryResult.sqlQuery,
              userId: currentUser.email,
              isPrivate,
              documentId: queryResult.documentId,
              explanation: queryResult.explanation,
            },
            context: { auth: true },
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to save query');
      }

      setIsQueryJustAdded(true);
      setTimeout(() => setIsQueryJustAdded(false), 3000);
    } catch (error) {
      console.error('Error saving query:', error);
      setError({
        message: 'Failed to save query. Please try again.',
        status: 'error',
      });
    }
  }, [queryResult, currentUser, setError, setIsQueryJustAdded]);

  const handleAddToDashboard = useCallback(async (dashboardId, name) => {
    if (!queryResult) return;
    
    try {
      const savedQueryResponse = await fetch(
        'https://us-central1-' + config.core.projectId + '.cloudfunctions.net/saveQuery',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            data: {
              name,
              userQuery: queryResult.userQuery,
              sqlQuery: queryResult.sqlQuery,
              userId: currentUser.email,
              isPrivate: true,
              documentId: queryResult.documentId,
              explanation: queryResult.explanation,
            },
            context: { auth: true },
          }),
        }
      );

      if (!savedQueryResponse.ok) {
        throw new Error('Failed to save query');
      }

      const savedQueryData = await savedQueryResponse.json();
      
      const dashboardResponse = await fetch(
        'https://us-central1-' + config.core.projectId + '.cloudfunctions.net/handleDashboardOperation',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            data: {
              operation: 'addQuery',
              dashboardId,
              queryId: savedQueryData.queryId,
              userId: currentUser.email,
            },
            context: { auth: true },
          }),
        }
      );

      if (!dashboardResponse.ok) {
        throw new Error('Failed to add query to dashboard');
      }

      setIsQueryJustAdded(true);
      setTimeout(() => setIsQueryJustAdded(false), 3000);
    } catch (error) {
      console.error('Error adding query to dashboard:', error);
      setError({
        message: 'Failed to add query to dashboard. Please try again.',
        status: 'error',
      });
    }
  }, [queryResult, currentUser, setError, setIsQueryJustAdded]);

  const handleDashboardUpdate = useCallback(() => {
    // Implement if needed for future dashboard-related functionality
  }, []);

  const dashboardManager = DashboardManager({
    setError,
    handleQueryResult,
    currentUser,
    isAdmin,
  });

  if (!currentUser) {
    return (
      <div className="text-center py-10">
        <h2 className="text-2xl font-bold mb-4">Please log in to view this page</h2>
        <p>You need to be logged in to access the query features.</p>
      </div>
    );
  }

// ... (previous imports and initial code remain the same)

  // Container classes - base container 
  const containerClasses = `
  container mx-auto px-0 sm:px-6 flex flex-col items-center justify-center min-h-[calc(100vh-64px)]
  `;

  // Query container - wider on mobile
  const queryContainerClasses = `
  relative mx-0 sm:mx-auto w-full
  transition-all duration-500 ease-in-out
  ${queryResult 
    ? 'w-full' 
    : 'md:w-[768px] w-full'
  }
`;

  // Keep the enhanced title animation
  const titleClasses = `
    text-2xl md:text-3xl font-bold mb-8 text-center text-gray-700
    transition-all duration-500 ease-in-out transform
    ${queryResult 
      ? 'scale-90 opacity-0 absolute top-0 left-1/2 -translate-x-1/2' 
      : 'scale-100 opacity-100 relative'
    }
  `;

  return (
    <div className={containerClasses}>
      <div className={titleClasses}>
        Ask me about your data
      </div>
      <div className={queryContainerClasses}>
        <QuerySection
          onQueryResult={handleQueryResult}
          onQueryStart={handleQueryStart}
          queryResult={queryResult}
          isLoading={isLoading || isConfigLoading}
          error={error}
          currentUser={currentUser}
          onSaveQuery={handleSaveQuery}
          onAddToDashboard={handleAddToDashboard}
          onFollowUpQuery={handleFollowUpQuery}
          mainContentRef={mainContentRef}
          submitQuery={submitQuery}
          onDashboardUpdate={handleDashboardUpdate}
          dashboardManager={dashboardManager}
          isQueryJustAdded={isQueryJustAdded}
          setIsQueryJustAdded={setIsQueryJustAdded}
          isAskPage={true}
          onClearResult={handleClearResult}
        />
      </div>
    </div>
  );
};

export default Ask;
// File: src/hooks/useFollowUpQuery.js

import { useState, useCallback } from 'react';

const useFollowUpQuery = (onSubmit) => {
  const [selectedText, setSelectedText] = useState('');
  const [selectedColumns, setSelectedColumns] = useState([]);  
  const [showIntermediatePopup, setShowIntermediatePopup] = useState(false);
  const [intermediatePopupPosition, setIntermediatePopupPosition] = useState({ x: 0, y: 0 });
  const [showFollowUpInput, setShowFollowUpInput] = useState(false);
  const [isFromHighlight, setIsFromHighlight] = useState(false);

  const handleTextSelection = useCallback((selectionData) => {
    if (selectionData.text) {
      setSelectedText(selectionData.text);
      setSelectedColumns(selectionData.columns);
      // Use the position from selectionData if available, otherwise use a default position
      setIntermediatePopupPosition(selectionData.position || {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2
      });
      setShowIntermediatePopup(true);
      setIsFromHighlight(true);
    } else {
      setShowIntermediatePopup(false);
    }
  }, []);

  const handleIntermediatePopupClick = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowIntermediatePopup(false);
    setTimeout(() => setShowFollowUpInput(true), 0);
  }, []);

  const handleFollowUpSubmit = useCallback(async (submittedQuery) => {
    if (onSubmit && typeof onSubmit === 'function') {
      await onSubmit(submittedQuery, { text: selectedText, columns: selectedColumns });
      setShowFollowUpInput(false);
      setSelectedText('');
      setIsFromHighlight(false);
      window.dispatchEvent(new Event('followUpQuery'));
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } else {
      console.error('onSubmit is not a function');
    }
  }, [onSubmit, selectedText, selectedColumns]);


  const handleClickOutside = useCallback((event, modalRef) => {
    if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('.intermediate-popup')) {
      setShowFollowUpInput(false);
      setShowIntermediatePopup(false);
      setSelectedText('');
      setIsFromHighlight(false);
    }
  }, []);

  const handleEscKey = useCallback((event) => {
    if (event.key === 'Escape') {
      setShowFollowUpInput(false);
      setShowIntermediatePopup(false);
      setSelectedText('');
      setIsFromHighlight(false);
    }
  }, []);

  const resetFollowUpState = useCallback(() => {
    setSelectedText('');
    setIsFromHighlight(false);
    setShowIntermediatePopup(false);
  }, []);

  return {
    selectedText,
    showIntermediatePopup,
    intermediatePopupPosition,
    showFollowUpInput,
    isFromHighlight,
    handleTextSelection,
    handleIntermediatePopupClick,
    handleFollowUpSubmit,
    handleClickOutside,
    handleEscKey,
    setShowFollowUpInput,
    resetFollowUpState
  };
};

export default useFollowUpQuery;
// src/utils/dataProcessing.js
import { format, parse, isValid } from 'date-fns';
import { currentConfig } from '../../contexts/ConfigContext';

export const formatDate = (dateValue, schemaType) => {
  if (!dateValue) return '';

  const dateString = typeof dateValue === 'object' && dateValue !== null
    ? (dateValue.value || dateValue.toString())
    : String(dateValue);

  const formats = [
    { format: 'yyyy-MM-dd HH:mm:ss', output: 'dd/MM/yyyy HH:mm:ss' },
    { format: 'yyyy-MM-dd', output: 'dd/MM/yyyy' },
    { format: 'yyyy-MM', output: 'MM/yyyy' },
    { format: 'MM-yyyy', output: 'MM/yyyy' },
    { format: 'yyyy', output: 'yyyy' },
    { format: 'MM/yyyy', output: 'MM/yyyy' },
    { format: 'MM/dd/yyyy', output: 'dd/MM/yyyy' },
    { format: 'dd/MM/yyyy', output: 'dd/MM/yyyy' },
    { format: 'yyyy/MM/dd', output: 'dd/MM/yyyy' },
    { format: 'MM yy', output: 'MM/yyyy' }
  ];

  if (schemaType) {
    if (schemaType.toLowerCase().includes('timestamp')) {
      const parsedDate = new Date(dateString);
      return isValid(parsedDate) ? format(parsedDate, 'dd/MM/yyyy HH:mm:ss') : dateString;
    } else if (schemaType.toLowerCase().includes('date')) {
      const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
      if (isValid(parsedDate)) {
        return format(parsedDate, 'dd/MM/yyyy');
      }
    }
  }

  for (const { format: formatString, output } of formats) {
    const parsedDate = parse(dateString, formatString, new Date());
    if (isValid(parsedDate)) {
      if (formatString.includes('HH:mm:ss')) {
        return format(parsedDate, 'dd/MM/yyyy HH:mm:ss');
      }
      if (formatString.includes('dd') || formatString.includes('DD')) {
        return format(parsedDate, 'dd/MM/yyyy');
      }
      return format(parsedDate, output);
    }
  }

  return dateString;
};

export const trimDecimals = (value) => {
  if (typeof value === 'number' || (typeof value === 'string' && !isNaN(parseFloat(value)))) {
    const num = typeof value === 'string' ? parseFloat(value) : value;
    return Number(num.toFixed(2));
  }
  return value;
};

// Keep original function signature but use config internally
export const formatNumber = (value, columnName) => {
  // Get exemptedColumns from config with fallback
  const exemptedColumns = currentConfig?.processing?.exemptedColumns || [];
  
  // Check if the column name is in the exempted list
  if (exemptedColumns.includes(columnName)) {
    return value;
  }

  if (typeof value === 'number' || (typeof value === 'string' && !isNaN(parseFloat(value)))) {
    const num = typeof value === 'string' ? parseFloat(value) : value;
    const absNum = Math.abs(num);
    
    if (absNum >= 1e9) {
      return (num / 1e9).toFixed(1) + 'B';
    } else if (absNum >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    } else if (absNum >= 1e3) {
      return (num / 1e3).toFixed(1) + 'k';
    } else {
      return num.toLocaleString('en-US', { 
        minimumFractionDigits: 0, 
        maximumFractionDigits: 2 
      });
    }
  }
  return value;
};

export const formatColumnName = (name) => {
  if (typeof name !== 'string') {
    console.warn(`Unexpected column name type: ${typeof name}`, name);
    return String(name);
  }
  return name
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const processData = (rawData, schema, isNumberFormatted) => {
  if (!Array.isArray(rawData)) {
    console.warn('Expected array for rawData, got:', typeof rawData);
    return [];
  }

  return rawData.map(item => {
    if (!item || typeof item !== 'object') {
      console.warn('Invalid item in rawData:', item);
      return {};
    }

    const processedItem = {};
    Object.entries(item).forEach(([key, value]) => {
      const fieldSchema = schema.fields.find(field => field.name === key);
  
      if (fieldSchema) {
        let displayValue, sortValue;
        switch (fieldSchema.type) {
          case 'DATE':
          case 'DATETIME':
          case 'TIMESTAMP':
          case 'STRING':
            if (value === null || value === undefined) {
              displayValue = '';
              sortValue = '';
            } else if (typeof value === 'object' && 'value' in value) {
              displayValue = formatDate(value.value, fieldSchema.type);
              sortValue = fieldSchema.type.toLowerCase().includes('date') || fieldSchema.type.toLowerCase().includes('timestamp')
                ? new Date(value.value).getTime()
                : String(value.value).toLowerCase();
            } else {
              displayValue = formatDate(value, fieldSchema.type);
              sortValue = fieldSchema.type.toLowerCase().includes('date') || fieldSchema.type.toLowerCase().includes('timestamp')
                ? new Date(value).getTime()
                : String(value).toLowerCase();
            }
            break;
          case 'INTEGER':
          case 'FLOAT':
          case 'NUMERIC':
            const trimmedValue = trimDecimals(value);
            displayValue = isNumberFormatted ? formatNumber(trimmedValue, key) : trimmedValue;
            sortValue = Number(trimmedValue);
            break;
          default:
            displayValue = value === null || value === undefined ? '' : value;
            sortValue = value === null || value === undefined ? '' : 
                        (typeof value === 'string' ? value.toLowerCase() : value);
        }
        processedItem[key] = { displayValue, sortValue };
      } else {
        processedItem[key] = { 
          displayValue: value === null || value === undefined ? '' : value, 
          sortValue: value === null || value === undefined ? '' : 
                     (typeof value === 'string' ? value.toLowerCase() : value)
        };
      }
    });
    return processedItem;
  });
};

export const sortData = (data, sortColumn, sortOrder) => {
  if (!Array.isArray(data) || data.length === 0 || !sortColumn) {
    return data;
  }

  return [...data].sort((a, b) => {
    let aValue = a[sortColumn]?.sortValue;
    let bValue = b[sortColumn]?.sortValue;

    if (typeof aValue === 'object' && aValue !== null && 'value' in aValue) {
      aValue = aValue.value;
    }
    if (typeof bValue === 'object' && bValue !== null && 'value' in bValue) {
      bValue = bValue.value;
    }

    if (aValue === undefined && bValue === undefined) return 0;
    if (aValue === undefined) return 1;
    if (bValue === undefined) return -1;

    const aDate = new Date(aValue);
    const bDate = new Date(bValue);
    if (isValid(aDate) && isValid(bDate)) {
      return sortOrder === 'asc' ? aDate - bDate : bDate - aDate;
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
    }

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortOrder === 'asc' 
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    const compareResult = String(aValue).localeCompare(String(bValue));
    return sortOrder === 'asc' ? compareResult : -compareResult;
  });
};
import React from 'react';

const BetaBadge = () => {
  return (
    <div className="relative inline-flex items-center group">
      <span className="inline-flex items-center justify-center h-4 px-1.5 ml-1.5 -mt-2.5 text-[10px] font-medium italic text-gray-700 bg-secondary/40 rounded-full hover:bg-secondary/50 transition-colors duration-200 cursor-default">
        beta
      </span>
      <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-3 py-2 text-sm text-gray-600 bg-white rounded-lg shadow-custom opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200 w-48 text-center">
        <div className="relative">
          This feature is in beta testing. We welcome your feedback!
          <div className="absolute w-2 h-2 bg-white transform rotate-45 left-1/2 -translate-x-1/2 -bottom-1"></div>
        </div>
      </div>
    </div>
  );
};

export default BetaBadge;
import React, { useMemo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ChartView from './ChartView';
import TableView from './TableView';
import { processData, sortData, formatColumnName } from './dataProcessing';

const ResultView = ({ 
  result, 
  viewMode, 
  chartType, 
  selectedXAxis, 
  selectedYAxis, 
  sortColumn, 
  sortOrder, 
  isSingleValue, 
  onProcessedDataChange,
  isNumberFormatted,
  onTextSelection,
  visibleColumns,
  columnOrder,
  onSortChange,
  chartRef
}) => {
  const [processedData, setProcessedData] = useState([]);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    console.log('ResultView props:', { result, viewMode, chartType, selectedXAxis, selectedYAxis, sortColumn, sortOrder, isSingleValue });
  }, [result, viewMode, chartType, selectedXAxis, selectedYAxis, sortColumn, sortOrder, isSingleValue]);

  useEffect(() => {
    if (result && Array.isArray(result.result) && result.schema) {
      try {
        const processed = processData(result.result, result.schema, isNumberFormatted);
        setProcessedData(processed);
        setError(null);
        if (typeof onProcessedDataChange === 'function') {
          onProcessedDataChange(processed, { viewMode, chartRef: chartRef.current });
        }
      } catch (err) {
        console.error('Error processing data:', err);
        setError('An error occurred while processing the data. Please try again.');
      }
    } else {
      console.error('Invalid result structure:', result);
      setError('Invalid data structure received. Missing result array or schema.');
    }
  }, [result, onProcessedDataChange, isNumberFormatted, viewMode]);

  const columns = useMemo(() => {
    if (result && result.schema && Array.isArray(result.schema.fields)) {
      return result.schema.fields.map(field => field.name);
    }
    console.warn('Invalid or missing schema:', result?.schema);
    return [];
  }, [result]);

  const defaultAxes = useMemo(() => {
    if (columns.length < 2) return { x: columns[0] || '', y: '' };
    const dateColumn = columns.find(col => 
      result.schema.fields.find(f => f.name === col && f.type.toLowerCase().includes('date'))
    );
    const numericColumn = columns.find(col => 
      result.schema.fields.find(f => f.name === col && ['INTEGER', 'FLOAT', 'NUMERIC'].includes(f.type.toUpperCase()))
    );
    return { 
      x: dateColumn || columns[0], 
      y: numericColumn || columns.find(col => col !== (dateColumn || columns[0])) || ''
    };
  }, [columns, result]);

  const formattedSelectedXAxis = useMemo(() => {
    if (!selectedXAxis || typeof selectedXAxis !== 'string' || !columns.includes(selectedXAxis)) {
      console.warn('Invalid selectedXAxis:', selectedXAxis);
      return defaultAxes.x;
    }
    return selectedXAxis;
  }, [columns, selectedXAxis, defaultAxes]);
  
  const formattedSelectedYAxis = useMemo(() => {
    if (!selectedYAxis || typeof selectedYAxis !== 'string' || !columns.includes(selectedYAxis)) {
      console.warn('Invalid selectedYAxis:', selectedYAxis);
      return defaultAxes.y;
    }
    return selectedYAxis;
  }, [columns, selectedYAxis, defaultAxes]);

  const defaultSortConfig = useMemo(() => {
    const xAxisField = result?.schema?.fields?.find(field => field.name === formattedSelectedXAxis);
    const isXAxisDate = xAxisField && xAxisField.type.toLowerCase().includes('date');
    
    if (isXAxisDate) {
      return { column: formattedSelectedXAxis, order: 'asc' };
    } else {
      return { column: formattedSelectedYAxis, order: 'asc' };
    }
  }, [result, formattedSelectedXAxis, formattedSelectedYAxis]);

  const sortedData = useMemo(() => {
    const effectiveSortColumn = sortColumn || defaultSortConfig.column;
    const effectiveSortOrder = sortOrder || defaultSortConfig.order;
    return sortData(processedData, effectiveSortColumn, effectiveSortOrder);
  }, [processedData, sortColumn, sortOrder, defaultSortConfig]);

  const handleTextSelection = (selectionData) => {
    if (typeof onTextSelection === 'function') {
      onTextSelection(selectionData);
    }
  };

  if (error) {
    return (
      <div className="text-left text-red-600">
        <p className="text-base font-bold">{error}</p>
      </div>
    );
  }

  // Enhanced check for no data or null results
  const hasNoData = !result || 
    !Array.isArray(result.result) || 
    result.result.length === 0 ||
    (result.result.length === 1 && Object.values(result.result[0]).every(value => value === null));

  if (hasNoData) {
    return (
      <div className="text-left">
        <p className="text-base font-bold flex items-center">
          There's no data matching your question. 🙃
        </p>
        <hr className="my-4 border-gray-300" />
        <p className="text-base">
          Please try adjusting your search terms or explore related topics, and we'll help you find what you need!
        </p>
      </div>
    );
  }

  if (isSingleValue && processedData.length > 0) {
    const [key, value] = Object.entries(processedData[0])[0];
    // Additional check for null value in single value display
    if (value === null || value.displayValue === null) {
      return (
        <div className="text-left">
          <p className="text-base font-bold flex items-center">
            There's no data matching your question. 🙃
          </p>
          <hr className="my-4 border-gray-300" />
          <p className="text-base">
            Please try adjusting your search terms or explore related topics, and we'll help you find what you need!
          </p>
        </div>
      );
    }
    return (
      <div className="text-left">
        <p className="text-base">{formatColumnName(key)}: <span className="text-base font-bold">{value.displayValue}</span></p>
      </div>
    );
  }

  if (processedData.length === 1 || Object.keys(processedData[0] || {}).length === 1) {
    return (
      <div className="relative">
        <TableView 
          data={sortedData} 
          schema={result.schema}
          initialSortColumn={sortColumn || defaultSortConfig.column}
          initialSortOrder={sortOrder || defaultSortConfig.order}
          onTextSelection={onTextSelection}
          visibleColumns={visibleColumns}
          columnOrder={columnOrder}
          onSortChange={onSortChange}
        />
      </div>
    );
  }

  const renderContent = () => {
    if (viewMode !== 'table') {
      return (
        <div ref={chartRef} className={`chart-container ${isMobile ? 'px-0' : 'px-4'}`}>
          <ChartView 
            data={sortedData}
            chartType={chartType}
            selectedXAxis={formattedSelectedXAxis}
            selectedYAxis={formattedSelectedYAxis}
            schema={result.schema}
            sortColumn={sortColumn || defaultSortConfig.column}
            sortOrder={sortOrder || defaultSortConfig.order}
          />
        </div>
      );
    } else {
      return (
        <div className="relative">
          <TableView 
            data={sortedData} 
            schema={result.schema}
            initialSortColumn={sortColumn || defaultSortConfig.column}
            initialSortOrder={sortOrder || defaultSortConfig.order}
            onTextSelection={onTextSelection}
            visibleColumns={visibleColumns}
            columnOrder={columnOrder}
            onSortChange={onSortChange}
          />
        </div>
      );
    }
  };

  return (
    <div className={`result-view-container ${isMobile ? 'p-0' : 'p-4'}`}>
      {renderContent()}
    </div>
  );
};

ResultView.propTypes = {
  result: PropTypes.shape({
    result: PropTypes.array,
    schema: PropTypes.shape({
      fields: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string
      }))
    })
  }),
  viewMode: PropTypes.oneOf(['chart', 'table', 'bar', 'line', 'pie']).isRequired,
  chartType: PropTypes.oneOf(['bar', 'line', 'pie']),
  selectedXAxis: PropTypes.string,
  selectedYAxis: PropTypes.string,
  sortColumn: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  isSingleValue: PropTypes.bool,
  onProcessedDataChange: PropTypes.func,
  isNumberFormatted: PropTypes.bool.isRequired,
  onSortChange: PropTypes.func,
  visibleColumns: PropTypes.arrayOf(PropTypes.string),
  columnOrder: PropTypes.arrayOf(PropTypes.string),
  chartRef: PropTypes.object 
};

ResultView.defaultProps = {
  chartType: 'bar',
  isSingleValue: false,
  visibleColumns: [],
  columnOrder: []   
};

export default ResultView;
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Card from '../components/Card';
import Button from '../components/Button';

const Optimization = () => {
  const [config, setConfig] = useState({ UserInputs: [] });
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const db = getFirestore();
        const configDoc = await getDoc(doc(db, 'Optimization', 'Configuration'));
        if (configDoc.exists()) {
          setConfig(configDoc.data());
        } else {
          console.error('Optimization Configuration document does not exist.');
        }
      } catch (err) {
        console.error('Error fetching configuration:', err);
      }
    };
    fetchConfig();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let parsedValue;
    
    const integerFields = ['press'];
    const floatFields = ['required_strength', 'total_batch_weight'];

    if (integerFields.includes(name)) {
      parsedValue = value === '' ? '' : parseInt(value, 10);
    } else if (floatFields.includes(name)) {
      parsedValue = value === '' ? '' : parseFloat(value);
    } else {
      parsedValue = value;
    }
    
    setInputs(prevInputs => ({ ...prevInputs, [name]: parsedValue }));
  };

  const formatLabel = (input) => {
    return input
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const isFormValid = () => {
    return config.UserInputs.every(input => {
      const value = inputs[input];
      if (['press'].includes(input)) {
        return Number.isInteger(value) && value >= 1;
      } else if (['required_strength', 'total_batch_weight'].includes(input)) {
        return typeof value === 'number' && value >= 0;
      } else if (['product_code'].includes(input)) {
        return typeof value === 'string' && value.trim() !== '';
      }
      return value !== undefined && value !== null && value !== '';
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    setResult(null);

    try {
      const functions = getFunctions();
      const getOptimalParameters = httpsCallable(functions, 'getOptimalParameters');
      
      const dataToSend = {
        press: inputs.press,
        product_code: inputs.product_code,
        required_strength: inputs.required_strength,
        total_batch_weight: inputs.total_batch_weight,
      };

      console.log('Sending data to backend:', dataToSend);

      const response = await getOptimalParameters(dataToSend);
      console.log('Backend Response:', response.data);
      setResult(response.data);

    } catch (err) {
      console.error('Error calling getOptimalParameters:', err);
      setError(err.message || 'An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };

  const formatNumber = (number) => {
    return Number(number).toFixed(2);
  };

  const renderParameterCard = (recommended, baseline, predictedStrength, baselinePredictedStrength, optimizationDetails) => {
    const requiredStrength = inputs.required_strength;
  
    const getStrengthDifference = (strength) => strength - requiredStrength;
    const optimizedDifference = getStrengthDifference(predictedStrength);
    const baselineDifference = getStrengthDifference(baselinePredictedStrength);
    
    const getColorClass = (strength) => strength < requiredStrength ? 'text-red-600' : 'text-gray-700';
  
    return (
      <Card 
        title="Optimization Results" 
        className="mb-8 bg-gradient-to-b from-white to-primary/10 border-t-4 border-primary p-4">
        <div className="grid grid-cols-2 gap-4 mb-4">
          {['Temperature', 'Feeder Speed', 'Current', 'Roller Gap'].map((key) => {
            const recommendedValue = recommended[key.toLowerCase().replace(' ', '_')];
            const baselineValue = baseline[key.toLowerCase().replace(' ', '_')];
            const difference = recommendedValue - baselineValue;
            const hasChange = Math.abs(difference) > 0.01; // Using a small threshold to account for floating-point imprecision
  
            return (
              <div 
                key={key} 
                className="bg-white p-3 rounded-lg shadow-sm relative z-10">
                <label className="text-sm font-medium text-gray-700 block mb-1">
                  {key}
                </label>
                <span className="text-lg font-bold block">
                  {formatNumber(recommendedValue)}
                </span>
                {hasChange && (
                  <div className="text-sm mt-1 text-blue-600">
                    {difference > 0 ? '+' : '-'}{formatNumber(Math.abs(difference))}
                  </div>
                )}
                <div className="mt-2">
                  <label className="text-xs font-medium text-gray-400 block">
                    Baseline
                  </label>
                  <span className="text-sm text-gray-500">
                    {formatNumber(baselineValue)}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        
        <div className="bg-gray-100 p-4 rounded-lg mt-6">
          <div className="flex justify-between items-start mb-4">
            <div className="flex-1">
              <label className="text-sm font-medium text-gray-700 block mb-1">
                Predicted Strength (Optimized)
              </label>
              <span className={`text-2xl font-bold ${getColorClass(predictedStrength)}`}>
                {formatNumber(predictedStrength)}
              </span>
            </div>
            <div className="flex-1 ml-8">
              <label className="text-sm font-medium text-gray-700 block mb-1">
                Predicted Strength (Baseline)
              </label>
              <span className={`text-2xl ${getColorClass(baselinePredictedStrength)}`}>
                {formatNumber(baselinePredictedStrength)}
              </span>
            </div>
          </div>
        </div>
  
        <div className="mt-6 bg-gray-50 p-4 rounded-lg">
          <h3 className="text-lg font-semibold mb-3">Optimization Details</h3>
          <ul className="space-y-2">
            <li><strong>Time Taken:</strong> {optimizationDetails.timeTaken} ms</li>
            <li><strong>Iterations:</strong> {optimizationDetails.iterations}</li>
            <li><strong>Finished At:</strong> {new Date(optimizationDetails.finishedAt).toLocaleString()}</li>
            <li><strong>First Adjusted Parameters:</strong> {optimizationDetails.firstAdjustedParams.join(', ')}</li>
            <li><strong>Optimization Log ID:</strong> {optimizationDetails.optimizationLogId}</li>
          </ul>
        </div>
      </Card>
    );
  };

  return (
    <div className="container mx-auto px-0 py-2 sm:py-4">
      <h1 className="text-gray-500 mb-6 text-xl font-semibold">Optimization</h1>
      
      <Card className="mb-8">
        <h2 className="text-lg font-semibold mb-4 text-gray-700">Input Parameters</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          {config.UserInputs.map((input) => (
            <div key={input} className="flex flex-col space-y-3">
              <label
                className="text-sm font-medium text-gray-700"
                htmlFor={input}
              >
                {formatLabel(input)}
              </label>
              <input
                className="bg-white block w-full sm:text-sm border-b border-gray-300 focus:border-secondary focus:ring-0 outline-none"
                id={input}
                type={
                  ['press'].includes(input)
                    ? 'number'
                    : (['required_strength', 'total_batch_weight'].includes(input) ? 'number' : 'text')
                }
                step={
                  ['required_strength', 'total_batch_weight'].includes(input)
                    ? '0.01'
                    : '1'
                }
                min={
                  ['press'].includes(input)
                    ? '1'
                    : (['required_strength', 'total_batch_weight'].includes(input) ? '0' : undefined)
                }
                placeholder={`Enter ${formatLabel(input)}`}
                name={input}
                value={inputs[input] || ''}
                onChange={handleInputChange}
                required
              />
            </div>
          ))}
          <div className="flex justify-end">
            <Button
              onClick={handleSubmit}
              disabled={!isFormValid() || isLoading}
              loading={isLoading}
              className="px-4 py-2"
            >
              Submit
            </Button>
          </div>
        </form>
      </Card>

      {error && (
        <Card className="mb-8 bg-red-50 border-red-500">
          <div className="text-red-700" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
          </div>
        </Card>
      )}

      {result && renderParameterCard(
        result.recommendedParameters, 
        result.baselineParameters, 
        result.predictedStrength,
        result.baselinePredictedStrength,
        {
          timeTaken: result.optimizationLog.timeTaken,
          iterations: result.optimizationLog.iterations,
          finishedAt: result.finishedAt,
          firstAdjustedParams: result.firstAdjustedParams,
          optimizationLogId: result.optimizationLogId
        }
      )}
    </div>
  );
};

export default Optimization;
import React, { createContext, useState, useContext, useEffect } from 'react';

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [selectedDashboard, setSelectedDashboard] = useState(() => {
    const saved = localStorage.getItem('selectedDashboard');
    if (!saved) {
      return null;
    }
    try {
      const parsed = JSON.parse(saved);
      return parsed && typeof parsed === 'object' ? parsed : null;
    } catch (error) {
      console.error('Error parsing selectedDashboard from localStorage:', error);
      localStorage.removeItem('selectedDashboard');
      return null;
    }
  });

  useEffect(() => {
    if (selectedDashboard) {
      try {
        localStorage.setItem('selectedDashboard', JSON.stringify(selectedDashboard));
      } catch (error) {
        console.error('Error stringifying selectedDashboard for localStorage:', error);
      }
    } else {
      localStorage.removeItem('selectedDashboard');
    }
  }, [selectedDashboard]);

  const updateSelectedDashboard = (dashboard) => {
    if (dashboard && typeof dashboard === 'object') {
      setSelectedDashboard(prevDashboard => {
        // Get the previous dashboard state
        const prevQueries = prevDashboard?.queries || [];
        
        // Map through the new queries while preserving settings
        const updatedQueries = dashboard.queries.map(newQuery => {
          // Find the corresponding previous query
          const prevQuery = prevQueries.find(q => q.id === newQuery.id);
          
          // Merge settings, prioritizing previous settings unless explicitly changed
          const mergedCardSettings = {
            // Default values
            viewMode: 'table',
            chartType: 'bar',
            sortColumn: '',
            sortOrder: 'asc',
            selectedXAxis: '',
            selectedYAxis: '',
            cardWidth: '100%',
            isNumberFormatted: true,
            // Previous settings (if they exist)
            ...(prevQuery?.cardSettings || {}),
            // New settings (only if they exist)
            ...(newQuery.cardSettings || {}),
            // Special handling for array values
            visibleColumns: Array.isArray(newQuery.cardSettings?.visibleColumns)
              ? newQuery.cardSettings.visibleColumns
              : Array.isArray(prevQuery?.cardSettings?.visibleColumns)
                ? prevQuery.cardSettings.visibleColumns
                : Object.keys(newQuery.result?.[0] || {}),
            columnOrder: Array.isArray(newQuery.cardSettings?.columnOrder)
              ? newQuery.cardSettings.columnOrder
              : Array.isArray(prevQuery?.cardSettings?.columnOrder)
                ? prevQuery.cardSettings.columnOrder
                : Object.keys(newQuery.result?.[0] || {})
          };
  
          return {
            ...newQuery,
            cardSettings: mergedCardSettings
          };
        });
  
        return {
          ...prevDashboard,
          ...dashboard,
          queries: updatedQueries
        };
      });
    } else {
      console.error('Invalid dashboard object provided to updateSelectedDashboard');
      setSelectedDashboard(null);
    }
  };

  const updateDashboardQuery = (queryId, updates) => {
    setSelectedDashboard(prevDashboard => {
      if (!prevDashboard) return null;
      return {
        ...prevDashboard,
        queries: prevDashboard.queries.map(query =>
          query.id === queryId ? { ...query, ...updates } : query
        )
      };
    });
  };

  const clearSelectedDashboard = () => {
    setSelectedDashboard(null);
  };

  return (
    <DashboardContext.Provider value={{ selectedDashboard, updateSelectedDashboard, updateDashboardQuery, clearSelectedDashboard }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
};
import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, functions, db } from '../firebase';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  onAuthStateChanged,
  sendPasswordResetEmail 
} from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';

const AuthContext = createContext();
const REGISTRATION_CACHE_KEY = 'registration_status';

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isRegistrationComplete, setIsRegistrationComplete] = useState(false);
  const [registrationChecked, setRegistrationChecked] = useState(false);

  // Cache management functions
  const getCachedRegistration = (email) => {
    try {
      const cached = localStorage.getItem(REGISTRATION_CACHE_KEY + '_' + email);
      if (cached) {
        const { status, userData } = JSON.parse(cached);
        return { status, userData };
      }
    } catch (error) {
      console.error('Error reading registration cache:', error);
    }
    return null;
  };

  const setCachedRegistration = (email, status, userData = null) => {
    try {
      localStorage.setItem(
        REGISTRATION_CACHE_KEY + '_' + email,
        JSON.stringify({ status, userData })
      );
    } catch (error) {
      console.error('Error setting registration cache:', error);
    }
  };

  const clearRegistrationCache = (email) => {
    try {
      localStorage.removeItem(REGISTRATION_CACHE_KEY + '_' + email);
    } catch (error) {
      console.error('Error clearing registration cache:', error);
    }
  };

  async function checkUserRegistration(email) {
    if (!email) {
      setIsRegistrationComplete(false);
      setRegistrationChecked(true);
      return { isRegistrationComplete: false };
    }
    
    // Check cache first
    const cachedData = getCachedRegistration(email);
    if (cachedData !== null) {
      console.log('Using cached registration status');
      setIsRegistrationComplete(cachedData.status);
      setRegistrationChecked(true);
      return { 
        data: { 
          isRegistrationComplete: cachedData.status,
          userData: cachedData.userData
        }
      };
    }

    try {
      console.log('Checking registration status from server');
      const checkRegistration = httpsCallable(functions, 'checkUserRegistration');
      const result = await checkRegistration({ email });
      
      const isComplete = result.data && result.data.isRegistrationComplete;
      setIsRegistrationComplete(isComplete);
      setRegistrationChecked(true);
      
      // Cache the result with user data
      setCachedRegistration(email, isComplete, result.data.userData);
      
      return result;
    } catch (error) {
      console.error('Error checking user registration:', error);
      setIsRegistrationComplete(false);
      setRegistrationChecked(true);
      return { data: { isRegistrationComplete: false } };
    }
  }

  async function saveUserPreferences(email, preferences) {
    try {
      console.log('Saving user preferences');
      const savePrefs = httpsCallable(functions, 'saveUserPreferences');
      const result = await savePrefs({
        email,
        hebrewName: preferences.hebrewName,
        englishName: preferences.englishName,
        role: preferences.role
      });

      // Update registration status and cache
      setIsRegistrationComplete(true);
      setCachedRegistration(email, true, {
        ...preferences,
        userId: email
      });

      // Force a token refresh after successful save
      if (currentUser) {
        await currentUser.getIdToken(true);
        await checkAdminStatus(currentUser);
      }

      console.log('User preferences saved successfully:', result);
      return result.data;
    } catch (error) {
      console.error('Error saving user preferences:', error);
      throw new Error('Failed to save user preferences: ' + error.message);
    }
  }

  async function signup(email, password) {
    console.log('Signup attempt');
    try {
      const checkAllowedEmail = httpsCallable(functions, 'checkAllowedEmail');
      const result = await checkAllowedEmail({ email });
  
      if (result.data.allowed) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const setUserRole = httpsCallable(functions, 'addUserRole');
        await setUserRole({ email });
  
        // Implement a more robust retry mechanism
        const maxRetries = 5;
        const delay = (retryCount) => new Promise(resolve => setTimeout(resolve, 2000 * (retryCount + 1)));
  
        for (let retries = 0; retries < maxRetries; retries++) {
          try {
            console.log(`Attempting token refresh, attempt ${retries + 1}`);
            await delay(retries);
            await userCredential.user.getIdToken(true);
            await checkAdminStatus(userCredential.user);
            console.log('Token refresh successful');
            return userCredential;
          } catch (error) {
            console.error('Token refresh attempt failed');
            if (retries === maxRetries - 1) throw new Error('Failed to complete signup. Please try again.');
          }
        }
      } else {
        throw new Error('Email not allowed to register');
      }
    } catch (error) {
      console.error('Error in signup:', error);
      throw error;
    }
  }

  async function login(email, password) {
    console.log('Login attempt');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful');
      return userCredential;
    } catch (error) {
      console.error('Error in login', error);
      throw new Error('Failed to log in. Please try again.');
    }
  }

  async function logout() {
    console.log('Logout attempt');
    try {
      // Clear registration cache for current user
      if (currentUser?.email) {
        clearRegistrationCache(currentUser.email);
      }
      
      await signOut(auth);
      setIsAdmin(false);
      setCurrentUser(null);
      setIsRegistrationComplete(false);
      setRegistrationChecked(false);
      console.log('Logout successful');
    } catch (error) {
      console.error('Error in logout');
      throw new Error('Failed to log out. Please try again.');
    }
  }

  async function resetPassword(email) {
    console.log('Password reset attempt');
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent successfully');
    } catch (error) {
      console.error('Error in resetting password');
      throw new Error('Failed to reset password. Please try again.');
    }
  }

  async function checkAdminStatus(user) {
    if (user) {
      try {
        console.log('Checking admin status');
        const idTokenResult = await user.getIdTokenResult(true);
        const newIsAdmin = !!idTokenResult.claims.admin;
        console.log('Admin status:', newIsAdmin);
        setIsAdmin(newIsAdmin);
        return newIsAdmin;
      } catch (error) {
        console.error('Error fetching token');
        setIsAdmin(false);
        return false;
      }
    } else {
      console.log('No user to check admin status');
      setIsAdmin(false);
      return false;
    }
  }

  async function forceTokenRefresh() {
    if (currentUser) {
      try {
        console.log('Forcing token refresh');
        await currentUser.getIdToken(true);
        await checkAdminStatus(currentUser);
      } catch (error) {
        console.error('Error forcing token refresh');
      }
    }
  }

  async function setAdminRole(email) {
    try {
      console.log('Setting admin role');
      const setUserRole = httpsCallable(functions, 'addUserRole');
      await setUserRole({ email });
      await forceTokenRefresh();
    } catch (error) {
      console.error('Error setting admin role');
      throw new Error('Failed to set admin role. Please try again.');
    }
  }

  useEffect(() => {
    console.log('Setting up auth state listener');
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log('User authenticated');
        setCurrentUser(user);
        
        // Use cached registration status if available
        const cachedData = getCachedRegistration(user.email);
        if (cachedData !== null) {
          console.log('Using cached registration data');
          setIsRegistrationComplete(cachedData.status);
          setRegistrationChecked(true);
          
          // Only check admin status since we have cached registration
          const adminStatus = await checkAdminStatus(user);
          setIsAdmin(adminStatus);
          setLoading(false);
        } else {
          // Run admin and registration checks in parallel if no cache
          Promise.all([
            checkAdminStatus(user),
            checkUserRegistration(user.email)
          ]).then(([adminStatus, regStatus]) => {
            console.log('Parallel auth checks completed');
            setIsAdmin(adminStatus);
            setIsRegistrationComplete(regStatus.data.isRegistrationComplete);
            setRegistrationChecked(true);
            setLoading(false);
          }).catch(error => {
            console.error('Error in parallel auth checks:', error);
            setIsAdmin(false);
            setIsRegistrationComplete(false);
            setRegistrationChecked(true);
            setLoading(false);
          });
        }
      } else {
        console.log('No user authenticated');
        setCurrentUser(null);
        setIsAdmin(false);
        setIsRegistrationComplete(false);
        setRegistrationChecked(true);
        setLoading(false);
      }
    });

    return () => {
      console.log('Cleaning up auth state listener');
      unsubscribe();
    };
  }, []);

  const value = {
    currentUser,
    isAdmin,
    isRegistrationComplete,
    registrationChecked,
    signup,
    login,
    logout,
    resetPassword,
    checkAdminStatus,
    forceTokenRefresh,
    setAdminRole,
    saveUserPreferences,
    checkUserRegistration
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Filter, Square, X, Check } from 'lucide-react';
import _ from 'lodash';

const FilterModal = ({ 
  isOpen, 
  onClose, 
  columnName, 
  data, 
  activeFilters,
  onApplyFilter,
  position,
  schema,
  style = {}
}) => {
  const [selectedValues, setSelectedValues] = useState(new Set());
  const [searchTerm, setSearchTerm] = useState('');
  const modalRef = useRef();

  const formatColumnName = (name) => {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  // Get unique values for the column
  const uniqueValues = useMemo(() => {
    if (!data || !columnName) return [];
    const values = data.map(row => {
      const value = row[columnName]?.displayValue ?? row[columnName];
      return value === null || value === undefined ? 'N/A' : String(value);
    });
    return _.uniq(values).sort();
  }, [data, columnName]);

  // Initialize selected values from active filters
  useEffect(() => {
    if (activeFilters && activeFilters[columnName]) {
      setSelectedValues(new Set(activeFilters[columnName]));
    } else {
      setSelectedValues(new Set(uniqueValues));
    }
  }, [columnName, activeFilters, uniqueValues]);

  // Handle Escape key
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) onClose();
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  const handleClickOutside = (event) => {
    if (isOpen && modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  const filteredValues = useMemo(() => {
    if (!searchTerm) return uniqueValues;
    return uniqueValues.filter(value => 
      value.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [uniqueValues, searchTerm]);

  const areAllSelected = selectedValues.size === uniqueValues.length;

  const handleToggleAll = () => {
    if (areAllSelected) {
      setSelectedValues(new Set());
    } else {
      setSelectedValues(new Set(uniqueValues));
    }
  };

  const handleValueToggle = (value) => {
    const newSelected = new Set(selectedValues);
    if (newSelected.has(value)) {
      newSelected.delete(value);
    } else {
      newSelected.add(value);
    }
    setSelectedValues(newSelected);
  };

  const handleApply = () => {
    onApplyFilter(columnName, Array.from(selectedValues));
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 z-50"
      onClick={handleClickOutside}
    >
      <div 
        ref={modalRef}
        className="bg-white rounded-lg shadow-xl animate-popIn flex flex-col"
        style={{
          position: 'fixed',
          top: `${position.top}px`,
          left: `${position.left}px`,
          width: '300px',
          maxHeight: '80vh',
          ...style
        }}
      >
        {/* Header */}
        <div className="bg-gray-100 px-6 py-4 flex justify-between items-center border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-800">Filter: {formatColumnName(columnName)}</h2>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800 transition-colors duration-150"
          >
            <X size={24} />
          </button>
        </div>

        <div className="px-6 py-4 flex-grow overflow-hidden flex flex-col">
          {/* Search Input */}
          <input
            type="text"
            placeholder="Search values..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-4 text-sm text-text placeholder-text-lighter focus:outline-none focus:ring-2 focus:ring-secondary focus:border-secondary transition-colors duration-200"
          />

          {/* Select/Deselect All Button */}
          <div className="mb-4 pb-3 border-b border-gray-200">
            <button
              onClick={handleToggleAll}
              className="flex items-center space-x-2 text-sm text-gray-700 hover:text-gray-900"
            >
              <div className={`p-1 rounded ${
                areAllSelected
                  ? 'text-blue-600 bg-blue-100'
                  : 'text-gray-400 bg-gray-200'
              }`}>
                {areAllSelected ? <Check size={14} /> : <Square size={14} />}
              </div>
              <span>
                {areAllSelected ? 'Deselect All' : 'Select All'}
              </span>
            </button>
          </div>

          {/* Values List */}
          <div className="overflow-y-auto flex-grow">
            {filteredValues.map((value) => (
              <div
                key={value}
                className="flex items-center px-3 py-2 hover:bg-gray-50 cursor-pointer transition-colors duration-200"
                onClick={() => handleValueToggle(value)}
              >
                <div className={`p-1 rounded ${
                  selectedValues.has(value)
                    ? 'text-blue-600 bg-blue-100'
                    : 'text-gray-400 bg-gray-200'
                }`}>
                  {selectedValues.has(value) ? <Check size={14} /> : <Square size={14} />}
                </div>
                <span className="text-sm text-gray-700 truncate ml-2">{value}</span>
              </div>
            ))}
          </div>
        </div>

        {/* Footer */}
        <div className="bg-gray-50 px-6 py-3 border-t border-gray-200 flex items-center justify-end gap-3">
          <button
            onClick={onClose}
            className="px-6 py-2.5 text-sm text-gray-600 hover:text-gray-900 font-medium transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleApply}
            className="px-6 py-2.5 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700 transition-colors duration-200"
          >
            Apply Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;